(function() {
var userFeed = new Instafeed({
    limit: 5,
    get: 'user',
    userId: '5353338107',
    accessToken: '5353338107.b472515.a188423f6f9a45198ba94d24ecf6db96',
    resolution: 'low_resolution',
    template: '<div id="instacard">' +
              '<div class="instacard-image"><a class="instagram-square" href="{{link}}"><img src="{{image}}" /></a></div>' +
                '<ul class="instacard-content" style="list-style:none;">' +
                  '<li class="instacard-location"><h3>{{location}}</h3></li>' +
                  '<li class="instacard-caption"><p>{{caption}}</p><a href="{{link}}">View Post</a></li>' +
                '</ul>' +
              '</div>'
});
userFeed.run();
})();